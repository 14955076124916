@import "../variables.scss";

.templatesPage {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  .header {
    padding-left: 1rem;
    font-size: 1.5rem;
  }

  .templates {
    display: grid;
    grid-template-columns: 1fr 3fr;

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Stack columns on smaller screens */
    }

    .leftPanel {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      max-height: calc(100vh - 15rem);
      overflow-y: auto;

      @media (max-width: 768px) {
        max-height: unset;
      }

      .clauses {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-height: calc(100vh - 30rem);
        overflow-y: auto;

        @media (max-width: 768px) {
          max-height: unset;
        }

        .clause {
          padding: 0.5rem;
          border: 1px solid $primary;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }

          &.global {
            background-color: $secondary;
            color: $white;
          }

          &.active {
            background-color: $lightBlue;
            color: $secondary;
          }

          .iconSection {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 65px;

            @media (max-width: 768px) {
              width: 100%;
              justify-content: flex-start;
            }
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            background-color: #f0f0f0;
            border-radius: 4px;
            padding: 8px;
            height: 20px;
          }

          .nameSection {
            flex: 1;
            padding-left: 10px;
          }

          .clauseName {
            font-size: 16px;
            font-weight: 500;
            text-align: left;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          .actionsSection {
            display: flex;
            gap: 10px;

            @media (max-width: 768px) {
              width: 100%;
              justify-content: flex-end;
            }

            .deleteIcon {
              cursor: pointer;
              color: red;
            }

            .cloneIcon {
              cursor: pointer;
              color: $secondary;
            }
          }
        }
      }
    }

    .rightPanel {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;

      .createNew {
        align-self: flex-end;

        @media (max-width: 768px) {
          align-self: stretch;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;

        @media (max-width: 768px) {
          padding: 0.5rem;
        }
      }

      .submitButton {
        align-self: flex-end;
        margin-top: 3rem;

        @media (max-width: 768px) {
          align-self: stretch;
        }
      }

      .editorContainer {
        border: 1px solid gray;
        border-radius: 4px;
        padding: 8px;

        @media (max-width: 768px) {
          padding: 5px;
        }
      }
    }
  }
}

.noLogoText {
  font-size: 18px;
  font-weight: bold;
  color: #ccc;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.clauseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }

  &.selectedClause {
    background-color: #e0e0e0;
  }

  &.blueBackground {
    background-color: rgb(58, 50, 218);
    color: white;
  }

  .clauseName {
    font-size: 16px;
    font-weight: 500;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .iconSection {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 65px;

    @media (max-width: 768px) {
      width: auto;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 8px;
    height: 20px;

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }

  .defaultIcon {
    color: green;
  }

  .nameSection {
    flex: 1;
    padding-left: 10px;
  }

  .clauseActions {
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
      justify-content: flex-end;
    }

    .deleteIcon {
      cursor: pointer;
      color: red;
    }

    .copyIcon {
      cursor: pointer;
      color: #0044cc;
    }
  }
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 900px;
  height: auto;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
}

.divider {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.custom-note {
  display: block;
  margin-top: 20px;
  font-weight: bold;
  color: rgb(221, 15, 15) !important;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
}

.content {
  border: 1px solid #0f0e0e;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.header {
  text-align: left;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.logoAndName {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
}

.dropzone {
  border: 2px dashed black;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  width: 850px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
    padding: 10px;
  }
}

.icon {
  margin-left: 8px;
}

.dropIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 220px;
  height: 150px;

  @media (max-width: 768px) {
    width: 150px;
    height: 100px;
  }
}

.dropzoneText {
  margin-top: 200px;
  font-style: italic;
  color: gray;
  font-size: 20px;

  @media (max-width: 768px) {
    margin-top: 100px;
    font-size: 16px;
  }
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.modelsubmitButton {
  align-self: flex-end;
  width: 100px;
  height: 40px;

  @media (max-width: 768px) {
    width: 80px;
    height: 35px;
  }
}

.displayContainer {
  display: flex;
  align-items: center;
}

@keyframes flash {
  0% {
    background-color: yellow;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: yellow;
  }
}

.flash {
  animation: flash 1s ease-in-out;
}

.subt {
  font-size: smaller;
  color: rgb(255, 85, 0);
}

.success {
  color: $green;
}

.error {
  color: $red;
}

.scrollToBottom {
  display: none; /* Hidden by default */
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: $primary; /* Use your primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  z-index: 1000;

  &:hover {
    background-color: darken($primary, 10%);
  }

  @media (max-width: 768px) {
    display: block; /* Visible only on mobile screens */
  }
}
.cke_update_warning {
  display: none !important;
}
