.could-container {
    position: relative;
    background-color: #539bc7;
    color: white;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.could-background-icon {
    position: absolute;
    z-index: 0;
    color: rgba(255, 255, 255, 0.152);
}

.could-left-icon {
    font-size: 120px;
    top: 61%;
    left: 0px;
    bottom: 0px;
    transform: translateY(-50%) rotate(20deg);
}

.could-right-icon {
    font-size: 360px;
    bottom: -100px;
    right: -100px;
    opacity: 0.4;
    transform: translateY(-50%) rotate(20deg);
}

.could-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.could-header,
.could-count,
.could-emphasize,
.could-unit {
    text-align: center;
    color: white;
    font-weight: bold;
}

.could-header {
    font-size: 1.2em;
}

.could-count {
    font-size: 3.5em;
}

.could-unit {
    font-size: 1.2em;
}

.could-emphasize {
    font-size: 1.4em;
}

.could-fixed-size-card {
    height: 190px;
}