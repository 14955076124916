@import '../variables.scss';

.login {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 90vh;
    .loginButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.footer {
  padding: 1rem 1rem;
  background-color: #f2f2f2;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
}

.footerContent {
  display: flex;
  align-items: center;
  gap: 1rem; /* Space between elements */
  text-align: center;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  overflow: hidden;
}

.logo {
  width: 90px; /* Adjust size as needed */
  height: auto;
}

.divider {
  width: 1px;
  height: 40px; /* Adjust height as needed */
  background-color: #ccc;
  margin: 0 1rem; /* Space around the divider */
}

.link {
  color: #007bff;
  text-decoration: none;
  margin: 0 0.5rem;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}
  