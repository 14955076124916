@import './../../variables.scss';

.purchaseDetails {
    padding: 2rem;
    .title {
        text-align: center;
        font-size: 2em;
        font-weight: bold;
        color: $secondary;
        margin: 1rem 0; 
    }
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        .form {
            display: flex;
            gap: 1rem;
            flex-direction: column;
        }
        .detsils {
            display: flex;
            gap: 1rem;
            flex-direction: column;
        }
    }
}
