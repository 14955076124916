@import './../../variables.scss';

.corporateForm {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
    .title{
        text-align: center;
        font-size: 2em;
        font-weight: bold;
        color: $secondary;
        margin: 1rem 0 0 0;
    }
    .cardLayout {
        display:flex;
        justify-content: space-evenly;
        align-items: center; 
    }

    .headerForm {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .submitButton {
            align-self: flex-end;
        }
        .roundedTextField {
            border-radius: 10px;
        }
    }

}
