@import '../variables.scss';

.dashboard {
  padding: 1rem;
  
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  tr {
    cursor: pointer;
    z-index: 1;
  }

  .archived {
    background: $gray;
  }

  .statusCell {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 36px;

    .status {
      height: 1rem;
      width: 1rem;
      border-radius: 100%;
      background-color: yellowgreen;

      &.draft {
        background-color: gray;
      }
      &.awaitingCompletion {
        background-color: lightgreen;
      }
      &.completed {
        background-color: green;
      }
    }
  }

  .smallCell {
    width: 20px;
  }

  .checkbox {
    z-index: 10000;
  }

  .createNew {
    color: white;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 1rem;

    div {
      width: 200px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
      div {
        width: 100%;
      }
    }
  }

  .pagination {
    margin-top: 1rem;
  }

  .clear {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  .inactiveBanner {
    background-color: orange;
    border: 2px solid blue;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    margin-bottom: 1px;
    color: black;
    font-weight: bold;

    a {
      color: red;
      text-decoration: none;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  .capturedImages {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }


  .footer {
    padding: 1rem 1rem;
    background-color: #f2f2f2;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ccc;
  }
  
  .footerContent {
    display: flex;
    align-items: center;
    gap: 1rem; /* Space between elements */
    text-align: center;
  }
  
  .pageContainer {
    display: flex;
    flex-direction: column;
    min-height: 94vh;
    overflow: hidden;
  }
  
  .logo {
    width: 90px; /* Adjust size as needed */
    height: auto;
  }
  
  .divider {
    width: 1px;
    height: 40px; /* Adjust height as needed */
    background-color: #ccc;
    margin: 0 1rem; /* Space around the divider */
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
    margin: 0 0.5rem;
    cursor: pointer;
  }
  
  .link:hover {
    text-decoration: underline;
  }

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffffff;
    padding: 0px;
    border-radius: 8px;
    border: 2px solid rgb(255, 255, 255);
    width: 90%; /* Adjust width (percentage or px) */
    max-width: 800px; /* Optional: Limit the maximum width */
    margin: 0 auto; /* Center the banner horizontally in its container */
  }
  

}
