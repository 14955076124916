@import '../variables.scss';

.thankyou {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    text-align: center;
    h1 {
        color: $secondary;
    }
    .success {
        color: green;
        font-size: 20px;
        font-weight: bold;
    }
    .warning {
        color: red;
    }
    .gray {
        color: $darkGray;
    }
    .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        width: 80%;
        padding: 1rem;
        border: 1px solid $darkGray;
        border-radius: 8px;
        max-width: 800px;
        p, h3 {
            margin: 0;
        }
        .group {
            display: flex;
            gap: 1rem;
            align-items: center;
        }
    }
}