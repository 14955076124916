// components/LogoPage.module.scss
.logoPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #fff; 
  }
  
  .logo {
    width: 1000px; 
  }
  