@import '../variables.scss';

.landing {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        color: $secondary;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 50%;
        align-items: center;
        .dropdown {
            width: 100%;
        }
    }
}