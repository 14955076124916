.templateEditor {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 20px auto;
  max-width: 1900px;
  min-height: 500px;
  font-family: 'Arial', sans-serif;
  display: flex; /* Use flexbox to display editor and image gallery side by side */
  flex-direction: row; /* Side-by-side layout */
  justify-content: space-between; /* Space between the columns */
}

.editorWrapper {
  display: flex;
  width: 100%;
}

.editorContainer {
  flex: 1; /* Takes up remaining space */
  margin-right: 20px; /* Space between the editor and the image gallery */
}

.imageGalleryContainer {
  width: 600px; /* Fixed width for the image gallery */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the gallery content */
  border: 1px solid #ddd; /* Optional: Add a border around the gallery box */
  border-radius: 8px; /* Optional: Round the corners */
  padding: 10px; /* Add some padding around the gallery */
  background-color: #fff; /* Optional: Set background color */
  max-height: 1200px; /* Limit the height of the image gallery */
  overflow-y: auto; /* Enable vertical scrolling when the content overflows */
}
.imageGallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 0;
  margin: 0;
}


.imageItem {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.galleryImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .templateEditor {
    flex-direction: column; /* Stack editor and image gallery on smaller screens */
  }

  .imageGalleryContainer {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Space above gallery */
  }

  .editorContainer {
    margin-right: 0; /* No margin on smaller screens */
  }
}
.inputField {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}

.dropdown {
  width: 100%;
  padding: 12px;
  margin: 10px 0 20px 0; /* Added bottom margin for gap */
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}

.saveButton {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  background-color: #ff9500;
  color: #fff;
  border: none;
  border-radius: 8px; /* Rounded edges */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #cf7317;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(1px); /* Pressed effect */
  }
}




.tabContainer {
  display: flex; /* Arrange children in a horizontal row */
  justify-content: space-around; /* Evenly distribute space between tabs */
  align-items: center; /* Align tabs vertically */
  border-bottom: 2px solid #ccc; /* Add a bottom border to separate tabs */
  margin-bottom: 1rem; /* Add spacing below tabs */
}

.tabButton {
  padding: 10px 20px; /* Add padding for better click area */
  border: none; /* Remove default border */
  background-color: transparent; /* Transparent background */
  font-size: 1rem; /* Adjust font size */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth hover effects */
}

.tabButton:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  color: #007bff; /* Blue text on hover */
}

.activeTab {
  font-weight: bold; /* Highlight active tab */
  color: #007bff; /* Active tab text color */
  border-bottom: 3px solid #007bff; /* Underline the active tab */
}


.refreshButton {
  background-color: #ffffff;  /* Example styling */
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  border: none;
  cursor: pointer;  /* Makes the button appear clickable */
  font-size: 16px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
}

.refreshButton:hover {
  background-color: #e87e00;  /* Darker shade on hover */
}
