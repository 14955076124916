.templateListBox {
  width: 400px; /* Fixed width for the box */
  height: 1050px; /* Let the height adapt to the content */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack header and list content */
}

.scrollableContainer {
  max-height: 1000px; /* Adjust the height as needed */
  overflow-y: auto;
  padding-right: 10px; /* Add some space for the scroll bar */
  padding: 15px;
}

h3 {
  margin: 10px 15px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.listItem {
  display: flex;
  justify-content: space-between; /* Space between the left side (icons) and right side (actions) */
  align-items: center;
  padding: 15px 25px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  background-color: #fefefe; /* Subtle background color */
  font-size: 18px;
  font-weight: 500;
}

.listItem:hover {
  background-color: #f5f5f5; /* Light gray background on hover */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle hover effect */
}

.highlighted {
  background-color: #fff3e0; /* Light orange highlight */
  border-left: 5px solid #ffa726; /* Orange border */
}

.leftSide {
  display: flex;
  align-items: center;
  gap: 2px; /* Adjust the gap between the icon and the text */
}

.templateName {
  flex: 1;
  text-align: left;
  margin-left: 20px; /* Align text to the left */
}


.templateName {
  flex: 1;
  text-align: left; /* Align text to the left */
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  color: red; /* Change color if needed */
  font-size: 16px;
  padding: 5px;
  transition: transform 0.2s;
}

.deleteButton:hover {
  transform: scale(1.1); /* Slightly enlarge the button on hover */
}

.deleteIcon {
  font-size: 20px; /* Adjust icon size */
}

.copyIcon {
  color: #2196f3; /* Blue color for copy icon */
  font-size: 20px;
}

.defaultIcon {
  color: #4caf50; /* Green color for default icon */
}

.userIcon {
  color: #000000; /* Yellow for user icon */
}

.publicIcon {
  color: #000000; /* Yellow for user icon */
}

.buildingIcon {
  color: #333232; /* Grey for building icon */
}

.archivedClausesHeader {
  border-top: 4px solid black; /* Add a thick line above */
  border-bottom: 4px solid black; /* Add a thick line below */
  padding: 10px 0; /* Optional: add some padding to create space */
  text-align: center; /* Optional: to center the text if desired */
}

.divider {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}


/* TemplateList.module.scss */

/* Styles for the filter buttons container */
.filterButtons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Base style for the filter buttons */
.filterButton {
  padding: 10px 20px;
  border: 2px solid #ff9800; /* Orange outline */
  border-radius: 25px; /* Rounded edges */
  background-color: transparent; /* Transparent background by default */
  color: #ff9800; /* Orange text color */
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;

  /* On hover */
  &:hover {
    background-color: rgba(255, 152, 0, 0.1); /* Slight orange background on hover */
  }

  /* Active state when clicked */
  &.active {
    background-color: #ff9800; /* Orange background when active */
    color: white; /* White text when active */
  }
}


.actionButton {
  display: flex;
  align-items: center; 
  justify-content: center; 
  padding: 10px 20px;
  font-size: 16px; 
  font-weight: bold;
  background-color: #f44336; 
  color: white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  gap: 8px; 
}

.actionButton:hover {
  background-color: #d32f2f; 
}

.buttonContent {
  display: flex;
  align-items: center; 
  gap: 5px; 
}

.sectionDivider {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  text-align: center;
  background-color: #f7f7f7;
}

.smallImage {
  width: 23px; 
  height: 20px;
}

.publicContainer {
  display: flex;
  align-items: center;
  gap: 4px; 
}