@import '../variables.scss';

.consentContainer {
  margin-top: 1rem;

  .consentSection {
    padding: 1.5rem;
    border: 1px solid $gray;
    box-shadow: 4px 7px 7px $lightGray;
    border-radius: 5px;
    margin: 2rem 2rem 0 2rem;
    min-height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .row {
      display: flex;
      justify-content: space-evenly;

      @media only screen and (max-width: 800px) {
        display: block;
      }

      .column {
        width: 50%;

        @media only screen and (max-width: 800px) {
          width: 100%;
        }
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .treatmentSection {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }

    .header {
      margin: 0 0 1rem 0;
      color: $secondary;
    }

    .dataItem {
      margin: 0 0 0.5rem 0;
      color: $black;
      font-weight: bold;
    }

    .subDataItem {
      margin: 0 0 0.5rem 0;
      color: $darkGray;
    }

    .label {
      margin: 0 0 1rem 0;
      color: $darkGray;
    }

    .treatmentPlans {
      color: $black;
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      &.editorContainer {
        border: 1px solid gray;
        border-radius: 4px;
        padding: 8px;
      }

      svg {
        &:hover {
          color: $primary;
        }
      }

      .editor {
        height: 75%;
      }

      @media screen and (min-width: 600px) {
        height: 55vh;
      }
    }

    .dropzone {
      height: 20rem;
      width: 25rem;
      border: 1px solid $gray;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .videos {
      display: flex;
      gap: 1rem;
      flex-direction: row-reverse;

      @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
      }

      .playListContainer {
        display: flex;
        flex-direction: column;
        width: 35%;
        max-height: 400px;

        @media only screen and (max-width: 800px) {
          width: 100%;
        }

        .tabList {
          display: flex;
          flex-direction: row-reverse;
          gap: 1rem;
        }



        .tab-container {
          width: 100%; /* Set to 100% of the parent element */
          max-width: 100%; /* Prevent it from exceeding the parent's width */
          overflow-x: auto; /* Enables horizontal scrolling */
        }
        
        .custom-tab-list {
          display: flex; /* Layout tabs in a row */
          white-space: nowrap; /* Prevent tabs from wrapping */
          width: max-content; /* Allow width to be based on content */
        }
        
        /* Custom scrollbar for WebKit browsers */
        .custom-tab-list::-webkit-scrollbar {
          height: 10px; /* Height of the scrollbar */
        }
        
        .custom-tab-list::-webkit-scrollbar-thumb {
          background-color: #888; /* Color of the scrollbar thumb */
          border-radius: 5px; /* Rounded corners for the thumb */
        }
        
        .custom-tab-list::-webkit-scrollbar-thumb:hover {
          background-color: #555; /* Darker color on hover */
        }
        
        .custom-tab-list::-webkit-scrollbar-track {
          background: #f1f1f1; /* Background for the scrollbar track */
        }


        .playList {
          overflow-y: scroll;
        }

        .video {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          gap: 1rem;

          @media only screen and (max-width: 800px) {
            flex-direction: column;
          }

          .thumbnail {
            width: 100px;
            height: auto;
            border-radius: 20px;
            padding: 10px;
          }

          .description {
            height: auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .disabled {}

            span {
              white-space: pre-line;
              padding: 1rem;
            }

            svg {
              &:hover {
                color: $primary;
              }
            }
          }

          .status {
            color: $darkGray;
          }
        }
      }

      .player {
        width: 65%;
        min-height: 300px;

        @media only screen and (max-width: 800px) {
          width: 100%;
          min-height: auto;
        }

        video {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }

    .noVideos {
      text-align: center;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      .buttonGroup {
        display: flex;
        gap: 1rem;
      }
    }

    .editorWrapper {
      width: 100%;
      max-width: 100%;
      overflow: hidden; /* Ensure no unwanted overflow */
      display: flex;
      flex-direction: column;
      flex: 1; /* Allow the editor to take up remaining space */
    }

    .editorToolbar {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white;
      padding: 1px;
      border-bottom: 1px solid #ddd;
    }

    .editorContent {
      width: 100%;
      max-width: 100%;
      min-height: 250px; 
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: pre-wrap; 
      box-sizing: border-box;
      padding: 3px; 
      border: 1px solid #ddd; 
      border-radius: 4px; 
      overflow: auto; 
      flex: 1; 
    }

    
  }
  .checkboxContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px; /* Optional: Adds space above the checkbox */
  }
  .dropdown {
    width: 100%;
    padding: 8px;
    font-size: 16px;
  border-radius: 9px;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer; /* Ensure it looks clickable */
    z-index: 10; /* Make sure it's not behind other elements */
  }
  
  .dropdown:focus {
    outline: none; /* Optional: Remove focus outline */
  }


  .ui-resizable {
    border: 1px dashed #ccc;
  }
  .ui-resizable-handle {
    width: 8px;
    height: 8px;
    background: #007bff;
    position: absolute;
    z-index: 90;
  }

}
.imageGalleryContainer {
  &.whiteBox {
    padding: 1rem; /* Reduced padding */
    background-color: white;
    border: 1px solid $gray;
    box-shadow: 4px 7px 7px $lightGray;
    border-radius: 5px;
    max-height: 905px; /* Restrict maximum height */
    overflow-y: auto; /* Add scrolling if content overflows */
  }
}
