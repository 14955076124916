@import '../../variables.scss';

.planCard {
    border: 1px solid $darkGray;
    border-radius: 16px !important;
    width: 300px;

    .title {
        font-size: 1.5rem;
        font-weight: 700;
        color: $secondary;
        text-align: center;
    }

    .divider {
        border-bottom: 1px solid $gray;
    }

    .list {
        .listItem {
            display: flex;
            gap: 1rem;
            svg {
                height: 1.5rem;
                width: 1.5rem;
                color: $green;
            }
        }
    }

    .price {
        text-align: center;
    }
    .oldPrice {
        text-align: center;
        text-decoration: line-through;
        color: $darkGray;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .action {
            width: 250px;
        }
    }

    .containedButton {
        border-radius: 9px;
        margin-bottom: 10px;
        width: 80%;
        outline: 2px solid transparent; 
        outline-offset: 2px;  
        background-color: rgb(237, 138, 9);          
      
        &:hover {
            background-color: rgba(79, 142, 218, 0.959);
            outline-color: #333; 
        }
    }
    
    
    
    .containedButton {
        border-radius: 9px;
        margin-bottom: 10px;
        width: 80%;
        outline: 2px solid transparent; 
        outline-offset: 2px;  
        background-color: rgb(237, 138, 9);          
      
        &:hover {
            background-color: rgba(79, 142, 218, 0.959);
            outline-color: #333; 
        }
    }
}





