@import './variables.scss';

.modal {
  background-color: white;
  margin: 4rem auto;
  width: 500px;
  border-radius: 8px;
  padding: 2rem;
}

.mg-b-1 {
  margin-bottom: 1rem !important;
}

.DraftEditor-editorContainer {
  max-height: 200px !important;
}

.MuiTab-labelIcon {
  display: flex !important;
  flex-direction: row-reverse !important;
  gap: 1rem;
}

.react-confirm-alert {
  text-align: center !important;
  position: relative;

  .react-confirm-alert-body{
    min-width: 310px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 50px 30px 30px 30px;
    text-align: center;
  }


  .react-confirm-alert-button-group {
    justify-content: center !important;
    &.custom-button {
      button {
        position: relative;
        min-width: 125px;
      }
    }
  }
}