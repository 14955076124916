.could-container {
    position: relative;
    background-color: #539bc7;
    /* Example color */
    color: white;
    padding: 20px;
    width: 90%;
    /* Set to the desired width, or use a specific value like 500px */
    max-width: 100px;
    /* Example max width, adjust as needed */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    /* Align the content to the bottom */
    justify-content: space-between;
    /* Space between the left icon and the content */
}

.could-background-icon {
    position: absolute;
    z-index: 0;
    color: rgba(255, 255, 255, 0.152);
    /* Light white with transparency for the icons */
}

.could-left-icon {
    font-size: 120px;
    /* Large size for the left icon */
    top: 61%;
    left: 0px;
    bottom: 0px;
    transform: translateY(-50%) rotate(20deg);
}

.could-right-icon {
    font-size: 360px;
    /* Even larger size for the bottom-right icon */
    bottom: -100px;
    /* Position it partially off-screen */
    right: -100px;
    opacity: 0.4;
    /* Very faint */
    transform: translateY(-50%) rotate(20deg);
}

.could-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
    text-align: center;
    /* Aligns text to the right */
}

.could-header,
.could-count,
.could-emphasize,
.could-unit {
    text-align: center;
    /* Right-align the text */
    color: white;
    /* White text */
    font-weight: bold;
    /* Bold text */
}

.could-header {
    font-size: 1.2em;
    /* Slightly larger text */
}

.could-count {
    font-size: 3.5em;
    /* Larger text for the count */
}

.could-unit {
    font-size: 1.2em;
    /* Slightly larger text */
}

.could-emphasize {
    font-size: 1.4em;
}
.could-fixed-size-card {
    height: 190px;
}