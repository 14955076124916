.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    height: 30rem;
    p {
        margin: 0;
    }
}