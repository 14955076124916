.container {
    position: relative;
    background-color: #fb9323d3;
    color: white;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.background-icon {
    position: absolute;
    z-index: 0;
    color: rgba(255, 255, 255, 0.152);
}

.left-icon {
    font-size: 120px;
    top: 54%;
    left: 0px;
    bottom: 0px;
    transform: translateY(-50%) rotate(20deg);
}

.right-icon {
    font-size: 360px;
    bottom: -140px;
    right: -100px;
    opacity: 0.4;
    transform: translateY(-50%) rotate(20deg);
}

.content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.header,
.count,
.unit {
    text-align: center;
    color: white;
    font-weight: bold;
}

.header {
    font-size: 1.2em;
}

.count {
    font-size: 3.5em;
}

.unit {
    font-size: 1.2em;
}

.fixed-size-card {
    height: 190px;
}