@import '../variables.scss';

.header {
    background-color: rgba($primary, 0.01) !important;
    img {
        height: 40px;
        width: auto;
    }
    .help {
        color: $primary !important;
    }
}