.templatesPage {
    display: flex; /* Flexbox for side-by-side layout */
    gap: 20px; /* Space between panels */
    padding: 20px; /* Padding around the container */
  }
  
  .sidePanel {
    width: 400px; /* Set width for the left panel */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add spacing between elements */
  }
  
  .searchFilter {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .searchInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .categoryFilter {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .leftPanel {
    height: calc(100vh - 250px); /* Full height, accounting for margins/paddings */
    // overflow-y: auto; /* Enable vertical scrolling */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mainContent {
    flex: 1; /* Fill the remaining space */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fefefe;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .templateEditor {
    width: 80%; /* Constrain content width */
    max-width: 800px; /* Limit maximum width */
    text-align: center;
  }
  
  .editorTextarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .saveButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: orange;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .saveButton:hover {
    background-color: darkorange;
  }
  
  
  
  .alignRightButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #0056b3;
    }
  }

.buttonContainer {
  display: flex;
  justify-content: flex-start; /* Align to the left */
  margin-bottom: 16px; 
  width: 600px;
  height: "48px",/* Adjust this value as needed for margin */
}

button {
  font-size: 16px; /* Adjust font size */
  padding: 12px 30px; /* Ensure padding is enough to fit the text */
  border-radius: 30px; /* Rounded corners */
  white-space: nowrap; /* Prevent text from wrapping */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 768px) {
  .buttonContainer {
    justify-content: center;
    padding: 5px; /* Adjust padding for smaller screens */
  }

  button {
    padding: 10px 20px; /* Smaller padding for mobile */
    font-size: 14px; /* Smaller font size on mobile */
  }
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  padding: 0px;
  border-radius: 8px;
  border: 2px solid rgb(255, 255, 255);
  width: 90%; /* Adjust width (percentage or px) */
  max-width: 800px; /* Optional: Limit the maximum width */
  margin: 0 auto; /* Center the banner horizontally in its container */
}
