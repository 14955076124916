@import '../variables.scss';

.headerForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .submitButton {
        align-self: flex-end;
    }
    
}
.banner {
    background-color: rgb(212, 120, 34);
    padding: 10px;
    border: 1px solid rgb(23, 22, 22);
    text-align: center;
    margin-bottom: 15px;
    color: white;
    border-radius: 8px; 
    font-weight: bold;
  }