/* PasswordPrompt.module.scss */

.passwordPrompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 300px;
    margin: 50px auto;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submitButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}

.cancelButton {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #b02a37;
  }
}
  
  .submitButton:hover {
    background-color: #e76c01;
  }
  
  .submitButton:active {
    background-color: #d76200;
  }
  
  .cancelButton {
    padding: 10px 20px;
    margin: 5px;
    background-color: #243474;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancelButton:hover {
    background-color: #1f2a5b;
  }
  .disabledButton {
    background-color: #d6d6d6; // Gray out the button
    color: #999; // Light gray text
    cursor: not-allowed; // Disable pointer events
  }