.app {
  padding: 20px;
}

.categoriesBox {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.header {
  padding-left: 1rem;
}

.categoryItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  &.active {
    background-color: #e0e0e0;
  }
}

.box {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.playerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.playlist {
  width: 30%;
  background-color: #f0f0f0; 
  padding: 20px;
  border-radius: 10px;
  margin-left: 20px; 
}

.playlistItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #e0e0e0;
  }

  &.active {
    background-color: #d0d0d0;
  }
}

.thumbnail {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
}

.categoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px; 
  border-bottom: 1px solid #ddd; 
  cursor: pointer;
}

.categoryName {
  flex: 1; 
}

.deleteIcon {
  color: red;
  margin-left: 16px; 
}

.scrollableContainer {
  max-height: 400px; /* Set the height you want the scrollable area to have */
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
}

.categoryContainer {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust the gap as needed */
}
