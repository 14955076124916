@import './../../variables.scss';

.myPlan {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem;
    .edit {
        position: absolute;
        right: 1rem;
        top: 5rem;
    }
    p {
        margin: 0;
    }
    .table {
        border-collapse: collapse;
        td {
            width: 240px;
            text-align: start;
            border: 1px solid $gray;
            padding: 0 1rem;
            &:first-child {
                width: 20px;
                text-align: center;
            }
            &:last-child {
                width: 100px;
                text-align: center;
            }
        }
        th {
            border: 1px solid $gray;
        }
    }
    .disabled {
        color: $gray;
        cursor: not-allowed;
        pointer-events: none;
    }

    .inactiveBanner {
        background-color: orange;
        border: 2px solid blue;
        border-radius: 15px;
        padding: 10px;
        text-align: center;
        margin-bottom: 1px;
        color: black;
        font-weight: bold;
        a {
            color: red;
            text-decoration: none;
        }
    }
    
}

