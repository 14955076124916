@import '../variables.scss';


.container {
    position: relative;
    background-color: #fff;
    margin: auto;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close {
    cursor: pointer;
  }
  
  .content {
    max-height: calc(80vh - 60px); // Adjust for padding/header
    overflow-y: auto;
  }
  
  .logs {
    width: 100%;
    border-collapse: collapse;
  }
  
  .logs th,
  .logs td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .logs th {
    background-color: #f4f4f4;
    text-align: left;
  }
