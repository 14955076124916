.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 950px;
  width: 90%;
  max-height: 80vh; /* Sets a maximum height for the modal */
  overflow-y: auto; /* Enables vertical scrolling when content overflows */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}



.actionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; // Pushes to the bottom of modal content
}
.react-confirm-alert-overlay {
  z-index: 1300 !important;
}

.react-confirm-alert {
  z-index: 1400 !important;
}