@import '../variables.scss';

.summary {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
        color: $secondary;
        text-align: center;
    }
    .success {
        color: green !important;
        font-weight: bold;
    }
    .pending {
        color: darkgoldenrod;
        font-weight: bold;
    }
    .error {
        color: red;
        font-weight: bold;
    }
    .activitySummary {
        padding-bottom: 2rem;
        border-bottom: 1px solid lightgray;
        .column {
            columns: 3;
            .dataItem {
                margin: 0;
                line-height: 2;
            }
        }
    }
    .stats {
        display: flex;
        gap: 1rem;
        justify-content: space-evenly;
        .video {
            width: 50%;
            border-right: 1px solid lightgray;
            display: flex;
            flex-direction: column;
            align-items: center;
            .videoItem {
                display: flex;
                gap: 1rem;
                margin-bottom: 1rem;
                .thumbnail {
                    max-width: 70px;
                    max-height: 70px;
                }
                .videoDetails {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        margin: 0;
                    }
                }
            }
            
        }
        .consent {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .buttonGroup {
                display: flex;
                gap: 1rem;
                align-items: center;
            }   
        }
    }
}
.warning {
    color: red;
}
.gray {
    color: $darkGray;
}
.modal {
    width: fit-content;
}
.signDigitally {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 80%;
    padding: 1rem;
    border: 1px solid $darkGray;
    border-radius: 8px;
    h3 {
        margin: 0;
    }
}

.videoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px; /* Reduced gap between videos */
  }
  
  .videoDetailsColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px; /* Reduced gap between thumbnail and details */
    width: 70%;
  }
  
  .thumbnail {
    width: 120px;
    height: 90px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .videoDetails {
    display: flex;
    flex-direction: column;
    gap: 4px; /* Reduced gap between text elements */
  }
  
  .title {
    font-weight: bold;
    margin: 0;
  }
  
  .pending,
  .lastAccessed {
    margin: 0;
  }
  
  .issueColumn {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .issueBox {
    color: red;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    background-color: #ffffff; /* Light red background */
    padding: 6px 12px; /* Adds padding inside the box */
    border-radius: 12px; /* Rounds the corners */
    border: 2px solid red; /* Adds a red border */
    display: inline-block; /* Ensures the box fits the content */
  }
  
  .divider {
    border: none;
    border-top: 1px solid #ccc; /* Divider line color */
    margin: 12px 0; /* Spacing around divider */
  }
  
.signManually {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 80%;
    padding: 1rem;
    border: 1px solid $darkGray;
    border-radius: 8px;
    margin-top: 1rem;
    h3 {
        margin: 0;
    }
    .column {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 1rem;
        .borderRight {
            border-right: 1px solid lightgray;
        }
        .columnSection {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p {
                text-align: center;
            }
        }
    }
}

