@import '../variables.scss';


.plans {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .plansToggle {
        align-self: center;
    }

    .title{
        text-align: center;
        font-size: 2em;
        font-weight: bold;
        color: $secondary;
        margin: 1rem 0 0 0;
        .smallText {
            font-size: 1.5rem;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            margin: 0;
        }
    }
    
    
    .cardLayout {
        display:flex;
        justify-content: space-evenly;
        align-items: stretch; 
    }

    .banner {
        background-color: rgb(212, 120, 34);
        padding: 10px;
        border: 1px solid rgb(23, 22, 22);
        text-align: center;
        margin-bottom: 15px;
        color: white;
        border-radius: 8px; 
        font-weight: bold;
      }
}






