$primary: #ec7c04;
$secondary: #243474;
$black: black;
$white: #fff;
$gray: #d4d4d4 ;
$darkGray: #979d84;
$lightGray: #E9EDC9;

$lightBlue: #C9EEFF;
$blue: #97DEFF;
$darkBlue: #62CDFF;

$red: #e75252;

$green: #4caf50;



